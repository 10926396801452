import { useEffect } from "react";
import CategoryList from "./CategoryList";
import CategoryMake from "./CategoryMake";
import MemoFix from "./MemoFix";
import MemoList from "./MemoList";
import { Routes, Route, useLocation } from 'react-router-dom';
import MemoMake from "./MemoMake";
import CategoryFix from "./CategoryFix";

const App = () => {

  const location = useLocation();

  useEffect(() => {
    document.body.onscroll = e => {
      e.preventDefault();
    }
    document.getElementById('root').onscroll = e => {
      e.preventDefault();
    }
  }, []);

  return (
    <>
      <Routes location={(location.state && location.state.hist1) || location}>
        <Route path="*" element={<CategoryList />} />
      </Routes>
      {location && location.state && location.state.hist1 &&
        <Routes location={(location.state && location.state.hist2) || location}>
        <Route path="/newcat" element={<CategoryMake />} />
          <Route path="/lstmem/:categoryId" element={<MemoList />} />
        </Routes>
      }
      {location && location.state && location.state.hist2 &&
        <Routes>
          <Route path="/newmem/:categoryId" element={<MemoMake />} />
          <Route path="/fixmem/:memoId" element={<MemoFix />} />
          <Route path="/fixcat/:categoryId" element={<CategoryFix />} />
        </Routes>
      }
    </>
  );
}

export default App;