import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

const MemoList = () => {
  
  const devMode = process.env.NODE_ENV === 'development';
  
  const { categoryId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const [slideoutFlag, setSlideoutFlag] = useState(false);
  const [headeruntop, setHeaderuntop] = useState(false);
  
  const [categoryName, setCategoryName] = useState('メモ一覧');
  const [memoList, setMemoList] = useState(null);
  
  const updateCategoryName = () => {
    if (devMode) {
      fetch(`http://${window.location.hostname}:8080/mempad/cat/get`, {method: 'post', body: JSON.stringify({id: categoryId})}).then(r=>r.json()).then(data=>{
        setCategoryName(data.name);
      });
    } else {
      const fd = new FormData();
      fd.append('id', categoryId);
      fetch('/mempad/cat/get', {method: 'post', body: fd}).then(r=>r.json()).then(data=>{
        setCategoryName(data.name);
      });
    }
  }

  const updateMemoList = () => {
    if (devMode) {
      fetch(`http://${window.location.hostname}:8080/mempad/mem/lst`, {method: 'post', body: JSON.stringify({id: categoryId})}).then(r=>r.json()).then(data=>{
        setMemoList(data);
      });
    } else {
      const fd = new FormData();
      fd.append('id', categoryId);
      fetch('/mempad/mem/lst', {method: 'post', body: fd}).then(r=>r.json()).then(data=>{
        setMemoList(data);
      });
    }
  }

  useEffect(() => {
    updateCategoryName();
    updateMemoList();
  }, [location]);


  const backFunc = () => {
    setSlideoutFlag(true);
    setTimeout(() => navigate(-1), 250);
  }

  const changeHeaderStyle = e => {
    if (e.target.scrollTop <= 0 && headeruntop) setHeaderuntop(false);
    else if (e.target.scrollTop > 0 && !headeruntop) setHeaderuntop(true);
  }

  return (
    <div id="memo-layout" className={slideoutFlag ? "layout layout-over-slideout" : "layout layout-over"}>
      <header className={headeruntop ? 'header-untop' : ''}>
        <a className="header-left" onClick={backFunc}>＜戻る</a>
        <h2 className="header-title">{categoryName}</h2>
        <Link className="header-right" to={`/newmem/${categoryId}`} state={{hist2: location, ...location.state}}>＋追加</Link>
      </header>
      <div className="top-scroller" onClick={e => e.target.parentElement.getElementsByTagName('main')[0].scrollTo({top:0, left:0, behavior: 'smooth'})} />
      <main onScroll={changeHeaderStyle}>
        {memoList && memoList.map((memo, key) =>
          <Link className={`content-box memo-list bd-col${memo.color}`} key={key} to={`/fixmem/${memo.id}`} state={{hist2: location, ...location.state}}>
            <b>{memo.title}</b>
            <hr className="memo-split" />
            {memo.content.split(/(\n)/).map((line, key) => 
              <React.Fragment key={key}>{line.match(/\n/) ? <br /> : line}</React.Fragment>
            )}
          </Link>
        )}
        <Link className="content-box memo-list info-color" to={`/fixcat/${categoryId}`} state={{hist2: location, ...location.state}}>カテゴリを編集</Link>
      </main>
    </div>
  );
}

export default MemoList;