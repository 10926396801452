import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ReactComponent as ShareIcon } from './share.svg';
ReactDOM.createRoot(document.getElementById('root')).render(
  !window.navigator.standalone ? 
    <div className="layout">
      <main>
        <div className="content-box">
          <br />
          <h2 className="header-title">アプリ登録の手順</h2>
          <br />
          <ul>
            <li>共有ボタン<ShareIcon />を選択</li>
            <li>スクロールして「ホーム画面に追加」を選択</li>
            <li>「追加」を選択</li>
            <li>ホーム画面に「メモパッド」が追加されます</li>
          </ul>
        </div>
      </main>
    </div>
  :
    <BrowserRouter>
      <App />
    </BrowserRouter>
);