import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const CategoryList = () => {

  const devMode = process.env.NODE_ENV === 'development';
  const alertMessage = `メモパッド 1.0.1

作成日:2023/9/9
更新日:2023/9/10

1.0.1 2023/9/10 - 表示改修メイン
・「このアプリの情報」「カテゴリの編集」の項目の文字色を変更しました。
・カテゴリ名とメモタイトルを太文字で表示するようにしました。
・カテゴリやメモの色設定を、背景色ではなく縁取色で表示されるように変更しました。
・「カテゴリ一覧」の文字が「一般」と表示されていた問題を修正しました。
・項目を長押しした際に、不適切な表示がされる問題を修正しました。
・ダークモードに対応しました。

1.0 2023/9/9 - 初期バージョン
`;

  const location = useLocation();
  const [headeruntop, setHeaderuntop] = useState(false);

  const [categories, setCategories] = useState(null);

  const updateCategories = () => {
    if (devMode) {
      fetch(`http://${window.location.hostname}:8080/mempad/cat/lst`, {method: 'post'}).then(r=>{return r.json()}).then(data=>{
        setCategories(data);
      });
    } else {
      fetch('/mempad/cat/lst', {method: 'post'}).then(r=>r.json()).then(data=>{
        setCategories(data);
      });
    }
  }

  useEffect(() => {
    updateCategories();
  }, [location]);

  const changeHeaderStyle = e => {
    if (e.target.scrollTop <= 0 && headeruntop) setHeaderuntop(false);
    else if (e.target.scrollTop > 0 && !headeruntop) setHeaderuntop(true);
  }

  return (
    <div className="layout">
      <header className={headeruntop ? 'header-untop' : ''}>
        <a className="header-left" onClick={updateCategories}>更新</a>
        <h3 className="header-title">カテゴリ一覧</h3>
        <Link className="header-right" to="/newcat" state={{hist1: location}}>＋追加</Link>
      </header>
      <div className="top-scroller" onClick={e => e.target.parentElement.getElementsByTagName('main')[0].scrollTo({top:0, left:0, behavior: 'smooth'})} />
      <main onScroll={changeHeaderStyle}>
        {categories && categories.map((category, key) =>
          <Link key={key} className={`listbox bd-col${category.color}`} to={`/lstmem/${category.id}`} state={{hist1: location}}><b>{category.name}</b></Link>
        )}
        <hr />
        <a className="listbox info-color" onClick={()=>alert(alertMessage)}>このアプリの情報</a>
      </main>
    </div>
  );
}

export default CategoryList;