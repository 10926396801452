import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const MemoMake = () => {
  
  const devMode = process.env.NODE_ENV === 'development';
  
  const { categoryId } = useParams();
  const [slideoutFlag, setSlideoutFlag] = useState(false);
  const [memoTitle, _setMemoTitle] = useState('');
  const [memoContent, _setMemoContent] = useState('');
  const [memoColor, setMemoColor] = useState(0);
  const [headeruntop, setHeaderuntop] = useState(false);
  const setMemoTitle = e => {_setMemoTitle(e.target.value)};
  const setMemoContent = e => {_setMemoContent(e.target.value)};

  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById('memo-title-input').focus();
  }, []);

  const backFunc = () => {
    setSlideoutFlag(true);
    setTimeout(() => navigate(-1), 250);
  }

  const changeHeaderStyle = e => {
    if (e.target.scrollTop <= 0 && headeruntop) setHeaderuntop(false);
    else if (e.target.scrollTop > 0 && !headeruntop) setHeaderuntop(true);
  }

  const makeMemo = () => {
    if (memoTitle.length > 0 && memoContent.length > 0) {
      if (devMode) {
        fetch(`http://${window.location.hostname}:8080/mempad/mem/new`, {method: 'post', body: JSON.stringify({title: memoTitle, content: memoContent, category: Number(categoryId), color: memoColor})}).then(() => {
          backFunc();
        });
      } else {
        const fd = new FormData();
        fd.append('title', memoTitle);
        fd.append('content', memoContent);
        fd.append('category', Number(categoryId));
        fd.append('color', memoColor);
        fetch('/mempad/mem/new', {method: 'post', body: fd}).then(() => {
          backFunc();
        });
      }
    }
  }

  return (
    <div id="memo-layout" className={slideoutFlag ? "layout layout-over-slideout" : "layout layout-over"}>
      <header className={headeruntop ? 'header-untop' : ''}>
        <a className="header-left" onClick={backFunc}>＜戻る</a>
        <h3 className="header-title">メモの追加</h3>
        <a className={memoTitle.length === 0 || memoContent.length === 0 ? 'header-right unactivelink' : 'header-right'} onClick={makeMemo}>＋作成</a>
      </header>
      <div className="top-scroller" onClick={e => e.target.parentElement.getElementsByTagName('main')[0].scrollTo({top:0, left:0, behavior: 'smooth'})} />
      <main onScroll={changeHeaderStyle}>
        <div className="content-box">
          <input id="memo-title-input" className="line-input" type="text" value={memoTitle} onChange={setMemoTitle} placeholder="メモタイトル" />
          <hr />
          <textarea placeholder="メモ本文" rows={10} value={memoContent} onChange={setMemoContent}></textarea>
          <hr />
          {[...Array(13)].map((_, key) => 
            <span key={key}>
              <input hidden id={`colordata-${key}`} type="radio" name="colordata" value={key} checked={key === memoColor} onChange={() => setMemoColor(key)}/>
              <label className={`category-color bg-col${key}`} htmlFor={`colordata-${key}`} />
            </span>
          )}
        </div>
      </main>
    </div>
  );
}

export default MemoMake;